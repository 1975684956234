@import '../../css/variables';

$width: 17.5rem;

.infoPanel {
    background-color: $secondary-accent;
    width: $width;
    animation: enterance 0.4s ease;
    overflow-x: hidden;

    .content {
        height: 100%;
        min-width: $width;
    }
}

@keyframes enterance {
    0% {
        opacity: 0;
        width: 0rem;
        transform: translateX(-1rem);
    }
    100% {
        opacity: 1;
        width: $width;
        transform: translateX(0rem);
    }
}
