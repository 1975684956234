@import '../../css/variables';

.container {
    margin-top: 19px;

    .workloadTypeYo {
        padding-right: 1rem;
        margin-right: 1rem;
        border-right: 1px solid $mono-dark;
    }
}
