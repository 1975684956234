@import '../../css/variables';

.mui-darkTextField {
    .MuiInputLabel-root {
        color: $slate-lightest;
    }

    .MuiOutlinedInput-root {
        background-color: $secondary-dark;

        .MuiOutlinedInput-notchedOutline {
            border-color: $black;
        }

        .MuiOutlinedInput-input {
            color: $white;
        }
    }
}
