@import '../../css/variables';

.SignupContainer {
    display: flex;
    flex-direction: row;
    height: 100%;

    .leftSide, .rightSide {
        flex: 1;
    }

    .rightSide {
        background-image: linear-gradient(180deg, $primary-light, $white);

        .graphicContainer {
            display: flex;
            overflow: hidden;

            .graphic { height: 100%; width: 100%; margin: auto; }
        }
    }

    .leftSide {
        padding: 2rem;
        overflow-y: auto;
        overflow-x: hidden;

        .leftContent {
            margin: auto;
            .image { margin-bottom: 2rem; }
        }
    }
}