@import '../../css/variables';

.coloursContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & > div {
        width: calc(15% - (2 * #{$main-padding}));
        height: 3rem;
        text-align: center;
        line-height: 3rem;
        border-radius: 50px;
        margin: $main-padding;
    }
}
