@import '../../css/variables';

.noStatusPaper {
    height: 300px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.noStatusMiddle {
    position: relative;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.noStatusTitle {
    text-align: center;
}

.noStatusBody {
    margin-top: 20px;
    width: 480px;
    overflow-wrap: break-word;
    text-align: center;
}
