@import '../../css/variables';

.spaceContentHeader {
    background-color: $white;
    position: relative;

    .addSpace {
        position: absolute;
        bottom: -1.25rem;
        right: 2rem;
    }
}

.cardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .spaceCard {
        width: 100%;
        height: 13rem;
        margin: 1rem;
        border-radius: $border-radius;

        @include bp(sm) { width: calc(50% - 2rem); }
        @include bp(md) { width: calc(50% - 2rem); }
        @include bp(lg) { width: calc(33% - 2rem); }
        @include bp(xl) { width: calc(25% - 2rem); }
        
        .spaceCardBody {
            padding: 1.5rem 0;

            .numCircle {
                border-radius: 50%;
                width: 4rem;
                height: 4rem;
                line-height: 4rem;
                background-color: $mono-light;
                text-align: center;
            }
        }
    }
}

.spaceCardTitle {
    font-size: .9rem;
    padding-left: 15px;
}

.notifCircle {
    border-radius: 50%;
    width: 1.25rem;
    height: 1.25rem;
    background-color: red;
    color: #fff;
    text-align: center;
}
