@import '../../css/variables';

.notificationPanel {
    position: absolute !important;
    top: 40px;
    right: 0;
    width: 20rem;
    height: 30rem;
    z-index: 1;
}
