@import '../../css/variables';

.spaceDetail_cardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .workloadCard {
        width: 19rem;
        height: 10rem;
        margin: 1rem;
        padding: 0;
        text-align: left;
        animation: enterance 0.3s ease;

        .cardBody {
            border-radius: $border-radius $border-radius 0 0;
            flex: 1;
            position: relative;
            padding: 1rem;
            min-height: 7rem;
            
            .statusIndicator {
                position: absolute;
                bottom: -9px;
                right: 0.75rem;
            }
        }

        .cardBodyAws { background-color: #ff9200; }
        .cardBodyAzure { background-color: #00a2f3; }
        .cardBodyGcp { background-color: #666666; }
        .cardBodyLoading { border-bottom: $border1; }

        .workloadCardTitle {
            font-size: 1rem;
            font-weight: bold;
            color: $white;
        }

        .cardBottom {
            padding: 0.75rem 1rem;
        }
    }
}

.spacesValue {
    font-size: 1.5rem;
    padding-top: 20px;
}
.spacesLabel {
    color: grey;
    padding-bottom: 20px;
}

.workloadCardHealth {
    margin-bottom: -1rem;
}

.noWorkloads {
    position: relative;
    float: left;
    top: 80%;
    left: 50%;
    transform: translate(-50%, 80%);
}

.noWorkloadsTitle {
    text-align: center;
    font-size: 1.25rem;
}

.noWorkloadsBody {
    margin-top: 20px;
    width: 470px;
    overflow-wrap: break-word;
    text-align: center;
}

.chipContainer { padding-left: 3.25rem !important; }

@keyframes enterance {
    0% { transform: scale(0.95); }
    100% { transform: scale(1); }
}
