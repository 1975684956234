@import '../../css/variables';

.container {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 15;
    background-color: rgba($secondary-dark, 0.76);
    animation: fade-in-background 0.3s ease;
    overflow: hidden;

    &.noAnimation {
        animation: none;
    }

    &.small .modal {
        max-width: 35rem;
    }

    &.large .modal {
        width: 80%;
        height: 80%;
    }

    &.fullsize {
        background-color: $white;
        animation: fade-in-fs-modal 0.3s ease;

        .modal {
            width: 37.5rem;
            height: 100%;
            border-radius: 0px;
            box-shadow: none;
            animation: none;
        }
    }

    .modal {
        margin: auto;
        width: auto;
        height: auto;
        background-color: $white;
        box-shadow: $shadow15;
        border-radius: $border-radius;
        animation: fade-in-modal 0.3s ease;

        .header {
            border-bottom: $border1;
            position: relative;
        }

        .content {
            overflow-y: auto;
        }
        
        .footer {
            border-top: $border1;
        }
    }
}

@keyframes fade-in-background {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fade-in-modal {
    0% {
        transform: scale(0.85);
        opacity: 0;
    }
    60% {
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        transform: scale(1);
    }
}

@keyframes fade-in-fs-modal {
    0% {
        transform: scale(0.85);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
