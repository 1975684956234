@import '../../css/variables';

.badge {
    padding: 0.125rem 0.5rem;
    border-radius: 50px;
    background-color: $slate;
    color: $white;

    &.danger { background-color: $danger; }
}
