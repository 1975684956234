@import '../../css/variables';

.legend {
   float: right;
    height: 25px;
}

.stoppedDot{
    height: 1rem;
    width: 1rem;
    background-color: $mono-light;
    border-radius: 50%;
    display: inline-block;
    margin-left: 25px;
    margin-right:20px;
}

.runningDot{
    height: 1rem;
    width: 1rem;
    background-color: $primary;
    border-radius: 50%;
    display: inline-block;
    margin-right: 20px;
}

.scheduler {
    display: block;
    align-items: center;
    overflow-y: hidden;
    flex-direction: column;
    height: 100%;

    & .rat-TimeSlot_component {
        background-color: $primary;
        border-radius: 5px;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        width:95%;
    }

    & .rat-DayHeader_events {
        display: none
    }

    & .rat-Week_allDayLabel {
        display: none
    }

    & .rat-TimeSlot_title::before {
        content: "Workload Running";
        display: block;
    }

    & .rat-Day_component {
        background: repeating-linear-gradient(
                        135deg,
                        #F2F4F8,
                        #F2F4F8 10px,
                        #FAFBFC 10px,
                        #FAFBFC 20px
        );

    }

    & .rat-AvailableTimes_component {
        height: auto;
    }
}

.schedulerDisabled {
    display: flex;
    align-items: center;
    overflow-y: hidden;

    & .rat-TimeSlot_component {
        background-color: $slate-lightest;
        border-radius: 5px;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        width:95%;
    }

    & .rat-DayHeader_events {
        display: none
    }

    & .rat-Week_allDayLabel {
        display: none
    }

    & .rat-TimeSlot_title::before {
        content: "Workload Running";
        display: block;
    }

    & .rat-Day_component {
        background: repeating-linear-gradient(
                        135deg,
                        #F2F4F8,
                        #F2F4F8 10px,
                        #FAFBFC 10px,
                        #FAFBFC 20px
        );
    }

    & .rat-Day_mouseTarget {
        pointer-events: none;
    }
}
