@import '../../css/variables';

.marginBottom { margin-bottom: $main-padding; }

.input { 
    .MuiPickersCalendarHeader-dayLabel {
        color: "red"
    }
}

// Recommendations schedule

.summarySchedule {
    margin-top: $main-padding;
    
    & > div:last-of-type {
        margin-left: $main-padding;
        padding-left: $main-padding;
        border-left: $border3;
    }
}
