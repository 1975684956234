@import 'src/css/variables.scss';

.dotIndicator {
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 50%;
    margin-right: 1rem;
    background-color: $slate-lightest;
    display: inline-block;
}
