@import '../../css/variables';

.sideNavContainer {
    background-color: $secondary;
    position: relative;
    width: 5rem;
    transition: all 0.25s ease-out;

    .logo {
        min-height: 4.9375rem;
        display: flex;
        & > .svg {
            display: block;
            margin: auto;
            width: 1.6875rem;
        }
    }

    .disabledScreen {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
    }

    .topButtons {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .navLink {
        border: none;
        background-color: transparent;
        outline: none;
        display: flex;
        flex-direction: row;
        color: $slate-light;
        align-items: center;
        margin: 1rem;
        width: calc(100% - (#{2 * $main-padding}));
        border-radius: $border-radius;
        position: relative;
        transition: all 0.2s;

        &:hover {
            color: $white;
        }

        &.panelBtn {
            width: 100%;
        }

        &.active {
            background-color: $primary;
            color: $white;
        }

        &.panelOpen {
            background-color: $secondary-accent;
        }

        .badge {
            position: absolute;
            top: 13px;
            right: 16px;
        }
    }

    .icon {
        display: block;
        font-size: 1.25rem;
        padding: 0.9375rem;
        text-align: right;
        
        &.recommendations { font-size: 1rem; }
    }

    .fab {
    }
}
