@import '../../css/variables';

.container {
    border: $border1;
    border-radius: $border-radius;

    & > .header {
        position: relative;
        & > .expandIcon {
            position: absolute;
            right: 1rem;
        }
    }

    & > .content {
        border-top: $border1;
    }
}
