@import url('https://fonts.googleapis.com/css?family=Rubik');
@import url('icomoon.css');
@import 'variables';
@import 'buttons';
@import 'typography';
@import 'components';
@import 'material-overrides';

* {
    box-sizing: border-box;
}

html, body {
    padding: 0;
    margin: 0;
    height: 100%;
}

html, body, .useVegaTypography {
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    color: $secondary;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.appContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow-y: auto;
}

.appContentContainer {
    flex: 1;
    background-color: $mono-light;
    overflow-y: auto;
}

a { cursor: pointer; }
a, a:hover { text-decoration: none; }
a.active { color: $primary !important; }

/********
 * Misc *
 ********/

.selectable { cursor: pointer; }
.invisible { visibility: hidden; }
.hidden { display: none; }
.noXOverflow { overflow-x: hidden; }
.noOverflow { overflow: hidden; }
.scrollContainer { overflow-y: auto; }
.noScrollbar::-webkit-scrollbar { display: none; }
.noScrollbar { -ms-overflow-style: none; }
.noBorderRadius { border-radius: 0; }
.noMargin { margin: 0 !important; }
.posRelative { position: relative; }

.padded {
    padding: $main-padding;
    hr { margin: $main-padding -$main-padding; }
}
.paddedTall { padding: 2rem $main-padding; }

hr {
    color: $mono-light;
    border: 1px solid;
    border-bottom: none;
}

.closeButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.5rem !important;
}

.chipContainer > * {
    margin-right: 1rem;
    &:last-child { margin-right: 0; }
}

/****************
 * Icons + SVGs *
 ****************/

button.iconButton, button.blank {
    border: none;
    outline: none;
    background-color: transparent;
}

button.iconButton {
    padding: 0.25rem;
}

.iconButton.big .icon, .icon.big { font-size: 1.25rem; }
.iconButton .icon.body1 { font-size: $body1Size; }
.iconButton .icon.body2 { font-size: $body2Size; }

// Icon Colors
.icon { color: inherit; }
.iconLabel, .iconButton { color: $slate-lightest; }
.iconButton:hover { color: $slate-light; }
.iconLabel.active, .iconButton.active { color: $primary; }
.icon.med { font-size: 1.5rem; }
.icon.large { font-size: 2rem; }

// SVGs and Logos
.vegaLogoLarge { height: $logo-height-large; }

.svgIconNormal, .cardIcon {
    width: $icon-width;
    height: $icon-width;
}

.cardIcon { margin-right: 1rem; }

.svgIconSmall { width: 2.5rem; }
.svgIconXSmall { width: 2rem; }

/****************
 * Page Layouts *
 ****************/

.pageHeaderContainer {
    position: relative;
    padding: $main-padding;

    &.light { background-color: $white; }
    &.bottomBorder { border-bottom: $border1; }
}

.marginNormal {
    margin: 1rem;
    &.viewContainer, .viewContainer { margin-top: 2 * #{$main-padding}; }
}

.pageCenterContainer {
    display: flex;
    overflow: hidden;
    position: relative;
    height: 100%;
}

.center {
    margin: auto;
    text-align: center;
    display: block;
}

.block { display: block; }
button.block { width: 100%; }

.fullHeight { height: 100%; }
.fullWidth { width: 100%; }

.topBottomMarginContainer > * {
    margin: #{$main-padding} 0;
    width: 100%;
    display: block;
}

.marginNormal-bottom { margin-bottom: $main-padding; }
.marginNormal-top { margin-top: $main-padding; }
.marginNormal-right { margin-right: $main-padding; }
.marginNormal-left { margin-left: $main-padding; }

/******************
 * Flexbox layout *
 ******************/

.flexCol {
    display: flex;
    flex-direction: column;
}

.flexRow {
    display: flex;
    flex-direction: row;

    &.alignItemsCenter { align-items: center; }
}

.alignItemsStart { align-items: flex-start; }
.flexCenter {
    align-items: center;
    justify-content: center;
}
.flexSeparate { justify-content: space-between; }
.flexRight { justify-content: flex-end; }
.flexLeft { justify-content: flex-start; }
.flexGrow { flex-grow: 1; }
.padRight > * { margin-right: 1rem; }
.justifyCenter { justify-content: center; }
.flex1 { flex: 1; }

/**********
 * Tables *
 **********/

table {
    width: 100%;
    border-collapse: collapse;

    th {
        text-align: left;
        font-weight: normal;
        color: $slate-lightest !important;
    }
}
