@import '../../css/variables';

$size: 2.75rem;

.fab {
    background-color: $primary !important;
    border-radius: 50%;
    height: $size;
    width: $size;
    box-shadow: $shadow13;
    color: $white;
    padding-top: 2px;
}
