@import '../../css/variables';

.pageFooter {
    display: flex;
    flex-direction: column;
    
    @include bp(lg) {
        flex-direction: row;
        justify-content: space-between;
    }

    background-color: $white;
    padding: $main-padding ($main-padding * 2);
    box-shadow: $shadow13;

    .text { flex: 1; }

    .buttons {
        padding-left: $main-padding * 2;
        &:last-child { margin-right: 0rem; }
        & > * { margin-right: $main-padding; }
    }
}
