@import '../../css/variables';

.icon {
    width: 40px;
    padding-top: 4px;
}

.col {
    width: 100%;
    padding-bottom: $main-padding;
}

.graph {
    padding: ($main-padding * 0.5) 0px;
}
