@import '../../css/variables';

.spinnerContainer {
    position: absolute;
    background-color: rgba($white, 0.7);
    height: 100%;
    width: 100%;
    display: flex;
    z-index: 5;

    .loading {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
