@import 'src/css/variables.scss';

.message {
    border-top: $border1;
    padding: $main-padding 0;

    &:first-of-type { border-top: none; }

    &:last-of-type {
        margin-bottom: 0px;
    }
}
