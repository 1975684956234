.createWorkloadHeader {
    width: 100%;
}

.createWorkloadTitle {
    font-size: 1.5rem;
    padding-top: 25px;
    padding-left: 10px;
}

.createWorkloadCard {
    height: 100%;
    width: 98%;
    margin-left: 15px;
    background-color: white;
}

.createWorkloadCardTitle {
    font-size: 1.5rem;
    padding-top: 15px;
    padding-left: 10px;
}

.createWorkloadCardDesc {
    font-size: 1.0rem;
    padding-top: 10px;
    padding-bottom: 25px;
    padding-left: 10px;
}

.createWorkloadCardBody {
    float: right;
    font-size: 1.0rem;
    width: 80%;
    padding-right: 200px;
}

.lineItem {
    text-align: left;
    padding-left: 20px;
    padding-top: 20px;
}

.createWorkloadInfo {
    padding-left: 10px;
}

.configLogo {
    width: 50 !important;
    height: 50;
}

.createWorkloadType {
    font-size: .8rem;
    padding-top: 5px;
    padding-left: 10px;
}

.cancelButton {
    margin: 20px !important;
    font-size: 1.0rem !important;
    color: white !important;
}

.createButton {
    margin: 20px !important;
    font-size: 1.0rem !important;
    color: white !important;
}

.detailsButton {
    text-align: center;
}

.workloadConfigTitle {
    display: block;
    font-size: 1.2rem;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 1rem;
}