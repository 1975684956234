@import '../../css/variables';

.left {
    flex: 3;
    background-color: $white;
}

.right {
    flex: 1;
}

.tabContent {
    padding: 1rem;
}

.pageContent {
    flex: 1;
}
