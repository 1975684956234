@import '../../css/variables';

.contentContainer {
    padding: 3rem;
    overflow-y: auto;
}

.footer {
    border-top: 1px solid $mono-light;
}

.darkContainer {
    margin-top: 2rem;
    background-color: $mono-lightest;
    margin-left: -3rem;
    margin-right: -3rem;
    padding: 1rem 3rem;
}

.iconContainer {
    align-self: center;
    display: flex;
    justify-content: center;

    & > div {
        display: inline-block;
        margin-right: 1rem;
    }
}

.cardTop {
    height: 7rem;
}

.cardBottom {
    margin-top: 1rem !important;
}
