.parkWorkloadHeader {
    display: inline-block;
    width: 100%;
}

.parkWorkloadHeaderTitle {
    float: left;
    font-size: 1.5rem;
    padding-top: 25px;
    padding-left: 10px;
}

.parkWorkloadTitleText {
    margin-top: -2.5rem;
    margin-left: 3rem;
}

.parkWorkloadHeaderCancel {
    float: right;
    padding-right: 40px;
}

.parkWorkloadCancelButton {
    background-color: lightgray !important;
}