.reportContainer {
  height: 100%;
  .quicksight-embedding-iframe {
    & .clickable-quicksight-attribution {
      display: none;
    }
  }
}

.reportErrorContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.linkContainer {
  text-align: center;
}