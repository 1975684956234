@import '../../css/variables';

.SignInContainer {
    display: flex;
    flex-direction: row;
    height: 100%;

    .leftSide, .rightSide {
        flex: 1;
        display: flex;
    }

    .leftSide {
        background-image: linear-gradient(230deg, $primary-light, $white);
        
        .graphicContainer {
            display: flex;
            overflow: hidden;

            .graphic { height: 100%; width: 100%; margin: auto; }
        }

        .createBtn { margin-top: 2rem; }
    }

    .rightSide {
        .rightContent {
            margin: auto;

            & > * { margin-bottom: 2rem !important; }
        }
    }
}

.modalContentWidth { width: 662px !important; }
