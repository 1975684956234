@import '../../css/variables';

.steps {
    margin-left: 10rem;
}

.addSpaceType {
    margin-right: 1rem;
    padding-right: 1rem;
    border-right: 1px solid $slate-lightest;
    line-height: 3.5rem;
}
