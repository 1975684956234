@import 'variables';

.c-slate-lightest { color: $slate-lightest; }
.c-slate-light { color: $slate-light; }
.c-slate { color: $slate; }
.c-slate-dark { color: $slate-dark; }
.c-white { color: $white; }
.c-secondary { color: $secondary; }
.c-secondary-dark { color: $secondary-dark; }
.c-secondary-accent { color: $secondary-accent; }
.c-secondary-shadow { color: $secondary-shadow; }
.c-black { color: $black; }
.c-primary-light { color: $primary-light; }
.c-primary { color: $primary; }
.c-primary-dark { color: $primary-dark; }
.c-success-light { color: $success-light; }
.c-success { color: $success; }
.c-success-dark { color: $success-dark; }
.c-warning-light { color: $warning-light; }
.c-warning { color: $warning; }
.c-warning-dark { color: $warning-dark; }
.c-danger-light { color: $danger-light; }
.c-danger { color: $danger; }
.c-danger-dark { color: $danger-dark; }
.c-mono-lightest { color: $mono-lightest; }
.c-mono-light { color: $mono-light; }
.c-mono { color: $mono; }
.c-mono-dark { color: $mono-dark; }

.bg-slate-lightest { background-color: $slate-lightest; }
.bg-slate-light { background-color: $slate-light; }
.bg-slate { background-color: $slate; }
.bg-slate-dark { background-color: $slate-dark; }
.bg-white { background-color: $white; }
.bg-secondary { background-color: $secondary; }
.bg-secondary-dark { background-color: $secondary-dark; }
.bg-secondary-accent { background-color: $secondary-accent; }
.bg-secondary-shadow { background-color: $secondary-shadow; }
.bg-black { background-color: $black; }
.bg-primary-light { background-color: $primary-light; }
.bg-primary { background-color: $primary; }
.bg-primary-dark { background-color: $primary-dark; }
.bg-success-light { background-color: $success-light; }
.bg-success { background-color: $success; }
.bg-success-dark { background-color: $success-dark; }
.bg-warning-light { background-color: $warning-light; }
.bg-warning { background-color: $warning; }
.bg-warning-dark { background-color: $warning-dark; }
.bg-danger-light { background-color: $danger-light; }
.bg-danger { background-color: $danger; }
.bg-danger-dark { background-color: $danger-dark; }
.bg-mono-lightest { background-color: $mono-lightest; }
.bg-mono-light { background-color: $mono-light; }
.bg-mono { background-color: $mono; }
.bg-mono-dark { background-color: $mono-dark; }

.dark-bg {
    h1, h2, h3, h4, h5, h6, p { color: $slate-light; }
}

h1, h2, h3, h4, h5, h6 {
    color: $secondary;
    font-weight: 400;
    margin: 0;
    padding: 0;

    &.white { color: $white; }
}

h1 {
    font-size: 3rem;
    line-height: 4.5rem;
}
h2 {
    font-size: 2.25rem;
    line-height: 3.5rem;
}
h3 {
    font-size: 1.75rem;
    line-height: 2.5rem;
}
h4 {
    font-size: 1.5rem;
    line-height: 2.5rem;
}
h5 {
    font-size: 1.125rem;
    line-height: 2rem;
    font-weight: 500;
}
h6 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
}

p {
    color: $secondary;
    margin-top: 0rem;
    margin-bottom: 1rem;
}

.subtitle1 {
    font-size: $body1Size;
    line-height: 2rem;
}

.subtitle2 {
    font-size: $body2Size;
    line-height: 1.5rem;
    font-weight: 500;
}

.body1 {
    font-size: $body1Size;
    line-height: 1.5rem;
}

.body2 {
    font-size: $body2Size;
    line-height: 1.5rem;
}

.caption {
    font-size: $captionSize;
    line-height: 1rem;
}

.iconButtonSize { font-size: $iconButtonSize; }

.ta-right {
    text-align: right;
}
.ta-left {
    text-align: left;
}

.bold {
    font-weight: bold;
}

ul, ol {
    li { margin-bottom: $main-padding; }
}
