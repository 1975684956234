@import '../../css/variables';

.chip {
    background-color: $mono-light;
    padding: 0.125rem;
    border-radius: 50px;
    font-size: $body2Size;
    color: $white;
    display: inline;

    & > .label {
        padding-left: 1.0rem;
        padding-right: 1.5rem;;
    }

    & > .icon {
        padding-left: 0.5rem;
    }
}
