@import 'variables';

.mui-checkbox-offset { margin-left: 35px; }

.MuiFormControl-root { width: 100% }
.MuiFormLabel-root, .MuiInputBase-root { font-size: 1rem !important; }
.MuiInputAdornment-root, .MuiInputAdornment-root .MuiTypography-root { color: $slate-lightest; }
.MuiTooltip-tooltip {
    background-color: $secondary-dark !important;
    font-size: $body2Size !important;
}
.MuiTooltip-arrow { color: $secondary-dark !important; }
.MuiBadge-badge {
    font-size: $captionSize !important;
    padding: 3px 4px !important;
}