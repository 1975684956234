@import 'variables';

/*********
 * Cards *
 *********/

.simpleCard {
    border-radius: $border-radius;
    box-shadow: $shadow13;
    background-color: $white;
    transition: box-shadow 0.25s;
    position: relative;
    border-width: 0px;

    &.selectable:hover:not(.disabled, :disabled) { box-shadow: $shadow15; }
    &.disabled, &:disabled {
        background-color: $mono-light;
        cursor: not-allowed;
    }
    & > .cardSelectControl {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
    }
    &.centerContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &.addCard {
        background-color: rgba($primary, 0.05);
        border: 1px solid rgba($primary, 0.2);
        box-shadow: none;

        &:hover { border-color: $primary; }
    }
}

.centeredCardContainer {
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.cardWrapper {
    width: $standard-card-size;
    margin: 1rem;

    .simpleCard {
        width: 100%;
    }

    .simpleCard.stdSize { height: $standard-card-size; }

    .belowCardText {
        margin-top: 1rem;
        color: $slate-light;
    }
}

/*********
 * Paper *
 *********/

.paper {
    border-radius: $border-radius;
    background-color: $white;
    
    &.dark { background-color: $mono-light; }
}

.paper.selected, .simpleCard.selected {
    border: 2px solid $primary;
}

/********************
 * Admiral Snackbar *
 ********************/

.snackbarContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    z-index: 1;
    
    &.top {
        bottom: unset;
        top: 1.875rem;
        & > .snackbar { box-shadow: $shadow10; }
    }

    &.bottom {
        bottom: 1.25rem;
        & > .snackbar {
            box-shadow: $shadow13;
            background-color: $slate;
            color: white;
        }
    }

    .snackbar, .admiralsnackbar {
        width: 100%;
        max-width: 28rem;
        box-shadow: $shadow14;
        margin: auto;
        height: $snackbar-height;

        &.primary {
            background-color: $primary;
            color: $white;
        }

        &.error {
            background-color: $danger;
            color: $white;
        }
    }
}

.snackbarMargin {
    padding-bottom: $snackbar-height + 16px !important;
}

/********
 * MISC *
 ********/

.notificationTile {
    width: 100%;
    border-bottom: 1px solid $mono-dark;
    padding: 1rem 0;

    &:first-of-type { padding-top: 0; }
}
