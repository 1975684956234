.detailsTop {
    width: 100%;
}

.detailsHeader {
    float: left;
    padding-top: 25px;
    padding-left: 100px;
}

.detailsTitle {
    font-size: 1.2rem;
}

.detailsClose {
    float: right;
    padding-top: 25px;
    padding-right: 20px;
}

.detailTab {
    width: 100%;
    padding-top: 25px;
    padding-left: 15px;
}
.selectWorkload {
    text-align: center;
    position: absolute;
    bottom: 90px;
    width: 100%;
}

.detailImg {
    background-image: url('../../images/3-Tier-Web.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 650px;
}