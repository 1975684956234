@import '../../css/variables';

.container {
    width: 14rem;
    border-right: $border1;

    section {
        margin: (2 * $main-padding) 0;
    }

    .navLink {
        display: block;
        width: 100%;
        margin: $main-padding 0;
        color: $slate;

        &:hover { color: $slate-light; }
    }
}
