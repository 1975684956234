@import '../../css/variables';

$step-line-height: 0.375rem;

.wizardStepper {
    display: flex;
    justify-content: center;

    .step {
        color: $slate-lightest;
        width: 2.3125rem;
        margin-right: 0.625rem;
        display: inline-block;
        text-align: center;
        position: relative;
        padding-bottom: $step-line-height;

        &:last-of-type { margin-right: 0; }

        &::after {
            background-color: $slate-lightest;
            content: '';
            width: 100%;
            position: absolute;
            height: $step-line-height;
            left: 0;
            border-radius: 10px;
        }

        .number {
            margin-bottom: 0.625rem;
        }

        &.active {
            color: $primary;
            &::after { background-color: $primary; }
        }

        &.previous {
            color: $primary-light;
            &::after { background-color: $primary-light; }
        }
    }
}