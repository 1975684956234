@import '../../css/variables';

.workloadStuff {
    height: 11.625rem;
}

.tallMargin {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.costHeader {
    margin: 2rem 0;
}

.cloudCard {
    min-width: 25rem;
    border-right: $border1;

    &.noBorder { border-right: none; }
}
