@import '../../css/_variables.scss';

.profilePanel {
    position: absolute !important;
    top: 40px;
    right: 0;
    width: 20rem;
    z-index: 1;
}

.avatarSize {
    font-size: 5rem !important;
}

.menuContainer {
    margin-top: 2 * $main-padding;
}

.menuButton {
    padding: $main-padding;
    margin: 0 -#{$main-padding};
    width: calc(100% + (#{2 * $main-padding}));
    border-top: $border1 !important;
    color: $slate-light;
    border-radius: 0;
    text-align: left;
    display: flex;

    & > div:first-of-type { flex-grow: 1; }
    &:last-of-type { margin-bottom: -$main-padding; }
}

.inviteContainer { margin-bottom: $sidebar-margin; padding: 0 $main-padding; }
