@import 'variables';

button {
    padding: 0;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    font-family: 'Rubik', sans-serif;
    cursor: pointer;
    outline: none;
    border-radius: 6px;
    border-width: 1px;
    border-style: solid;
    transition: all 0.25s linear;

    &::-moz-focus-inner {
        border: 0;
    }
}

button.disabled, button:disabled { cursor: not-allowed; }

/****************
 * Button Sizes *
 ****************/

button.medium {
    height: 2.1875rem;
    padding: 0 3.125rem;
}

button.large {
    height: 2.5625rem;
    padding: 0 4.5rem;
}

button.small {
    height: 1.75rem;
    padding: 0 1.25rem;
}

/**********************
 * Button Type Styles *
 **********************/

@mixin buttonColors($bgColor, $important:null, $color: $bgColor) {
    background-color: $bgColor $important;
    color: $color;
    border-color: $bgColor $important;
}

button.normal {
    box-shadow: $shadow7;
    color: $white !important;
    border: none;
}

button.flat {
    color: $white !important;
    border: none;
}

button.accent {
    border: none;
}

button.text {
    background-color: transparent !important;
    border: none;

    &:hover { background-color: transparent; }
}

button.normal.disabled,
button.flat.disabled,
button.normal:disabled,
button.flat:disabled,
button.accent:disabled {
    @include buttonColors($slate-lightest, !important);
}
button.text.disabled,
button.text:disabled {
    color: $slate-lightest !important;
}
button.primary { @include buttonColors($primary); }
button.success { @include buttonColors($success); }
button.danger { @include buttonColors($danger); }
button.slate-lightest { @include buttonColors($slate-lightest); }
button.slate-light { @include buttonColors($slate-light); }

button.accent.primary { @include buttonColors(rgba($primary, 0.15), !important, $primary); }
button.accent.success { @include buttonColors(rgba($success, 0.15), !important, $success); }
button.accent.danger { @include buttonColors(rgba($danger, 0.15), !important, $danger); }
button.accent.slate-lightest { @include buttonColors(rgba($slate-lightest, 0.15),!important , $slate-lightest); }
button.accent.slate-light { @include buttonColors(rgba($slate-light, 0.15),!important , $slate-light); }
button.accent:disabled { @include buttonColors(rgba($slate-lightest, 0.15), !important, $slate-lightest); }

/****************
 * Hover States *
 ****************/

button.accent.primary:not(:disabled):not(.disabled):hover { @include buttonColors($primary, !important, $white); }
button.accent.success:not(:disabled):not(.disabled):hover { @include buttonColors($success, !important, $white); }
button.accent.danger:not(:disabled):not(.disabled):hover { @include buttonColors($danger, !important, $white); }
button.accent.slate-lightest:not(:disabled):not(.disabled):hover { @include buttonColors($slate-lightest, !important, $white); }
button.accent.slate-light:not(:disabled):not(.disabled):hover { @include buttonColors($slate-light, !important, $white); }
button.accent:disabled:hover { color: $slate-lightest; }

button.primary:hover { @include buttonColors($primary-dark); }
button.success:hover { @include buttonColors($success-dark); }
button.danger:hover { @include buttonColors($danger-dark); }
