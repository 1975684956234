@import '../../css/variables';

.roundCloseButton {
    position: absolute;
    top: 2rem;
    right: 2rem;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    display: block;
    line-height: 3.3rem;
    background-color: $slate-light;
    border: none;
    z-index: 5;

    .icon, .icon:hover { color: white; }
}
