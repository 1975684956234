@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?7wavw9');
  src:  url('fonts/icomoon.eot?7wavw9#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?7wavw9') format('woff2'),
    url('fonts/icomoon.ttf?7wavw9') format('truetype'),
    url('fonts/icomoon.woff?7wavw9') format('woff'),
    url('fonts/icomoon.svg?7wavw9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-up:before {
  content: "\e920";
}
.icon-chevron-down:before {
  content: "\e921";
}
.icon-cloud-search:before {
  content: "\e935";
}
.icon-file-chart:before {
  content: "\e936";
}
.icon-cloud-print:before {
  content: "\e937";
}
.icon-domain:before {
  content: "\e938";
}
.icon-account-key:before {
  content: "\e939";
}
.icon-account-multiple:before {
  content: "\e934";
}
.icon-import-resources:before {
  content: "\e927";
}
.icon-satellite:before {
  content: "\e928";
}
.icon-reports:before {
  content: "\e93a";
}
.icon-trash-can:before {
  content: "\e931";
}
.icon-update-schedule:before {
  content: "\e926";
}
.icon-next-arrow:before {
  content: "\e925";
}
.icon-clock:before {
  content: "\e933";
}
.icon-open-sidebar:before {
  content: "\e92f";
}
.icon-recommendations:before {
  content: "\e930";
}
.icon-back-arrow:before {
  content: "\e924";
}
.icon-list:before {
  content: "\e923";
}
.icon-arrow-down:before {
  content: "\e91d";
}
.icon-arrow-up:before {
  content: "\e91e";
}
.icon-backup:before {
  content: "\e91f";
}
.icon-park:before {
  content: "\e922";
}
.icon-log-out-icon:before {
  content: "\e932";
}
.icon-support:before {
  content: "\e92e";
}
.icon-box:before {
  content: "\e929";
}
.icon-cloud:before {
  content: "\e92a";
}
.icon-compute:before {
  content: "\e92b";
}
.icon-database:before {
  content: "\e92c";
}
.icon-network:before {
  content: "\e92d";
}
.icon-tasks:before {
  content: "\e900";
}
.icon-sunburst-chart:before {
  content: "\e901";
}
.icon-stepper-icon:before {
  content: "\e902";
}
.icon-spaces:before {
  content: "\e903";
}
.icon-show:before {
  content: "\e904";
}
.icon-settings:before {
  content: "\e905";
}
.icon-search:before {
  content: "\e906";
}
.icon-plus:before {
  content: "\e907";
}
.icon-notifications:before {
  content: "\e908";
}
.icon-More:before {
  content: "\e909";
}
.icon-mobile:before {
  content: "\e90a";
}
.icon-menu-down:before {
  content: "\e90b";
}
.icon-it-central:before {
  content: "\e90c";
}
.icon-it-business-unit:before {
  content: "\e90d";
}
.icon-info:before {
  content: "\e90e";
}
.icon-home:before {
  content: "\e90f";
}
.icon-hide:before {
  content: "\e910";
}
.icon-favorites:before {
  content: "\e911";
}
.icon-email:before {
  content: "\e912";
}
.icon-dashboard:before {
  content: "\e913";
}
.icon-close:before {
  content: "\e914";
}
.icon-chevron-right:before {
  content: "\e915";
}
.icon-chevron-left:before {
  content: "\e916";
}
.icon-check:before {
  content: "\e917";
}
.icon-bar-chart:before {
  content: "\e918";
}
.icon-alert:before {
  content: "\e919";
}
.icon-admin:before {
  content: "\e91a";
}
.icon-activity:before {
  content: "\e91b";
}
.icon-account:before {
  content: "\e91c";
}
