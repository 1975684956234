@import '../../css/variables';

.headerHeight { height: 7rem; min-height: 7rem; }

.chipContainer { padding-left: 3.25rem !important; }

.noPaddingTop { padding-top: 0 !important; }

.container {
    border-bottom: $border1;
}
