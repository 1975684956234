@import 'src/css/variables.scss';

.message {
    border-top: $border2;
    padding: (2 * $main-padding) 0;
    
    &:last-of-type {
        border-bottom: $border2;
        margin-bottom: 0px;
    }

    .statusIndicator {
        margin-bottom: $main-padding;
    }

    .buttons {
        margin-top: 2 * $main-padding;
    }
}
