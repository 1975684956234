@import '../../css/variables';

.rightContainer {
    justify-content: flex-end;
    margin-bottom: $main-padding;
    z-index: 12;
}

.rightContainer > * {
    height: $breadcrumb-height;
    margin-right: #{2.5 * $main-padding};
    &:last-child { margin-right: 0; }
}
