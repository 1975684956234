@import '../../css/variables';

.addPCPContent {
    max-width: 42rem;
}

.createWorkloadHeader {
    display: inline-block;
    width: 100%;
}

.import { margin-top: 1rem; }

.workloadType {
    max-width: 15rem;
    margin: 1rem;

    .iconContainer {
        height: 10rem;
        color: $slate-light;
    }
}
