@import '../../css/variables';

.vegaBreadcrumbsContainer {
    height: $breadcrumb-height;
    margin-bottom: $main-padding;
}

.linkText {
    text-transform: capitalize;
    font-size: 1rem !important;
}

.vegaBreadcrumbs {
    font-size: 1rem !important;
    width: 100%;
}

.vegaBreadcrumbsDark { background-color: $mono-light; }
.vegaBreadcrumbsLight { background-color: $white; }
