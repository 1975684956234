@import '../../css/variables';

.tabHeaderContainer {
    display: flex;
    border-bottom: 1px solid $mono;
    
    &.centered { justify-content: center; }

    .tab {
        color: $slate-lightest;
        padding: 0.5rem;
        margin: 0 1rem;
        border-radius: 0;
        border-bottom: 3px solid transparent;
    }

    .tab:not(.active):hover { color: $slate-light; }

    .tab.active {
        color: $primary;
        border-bottom-color: $primary;
    }
}
