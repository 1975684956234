@import '../../css/variables';

.container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $mono-light;

    .content { animation: fade-in 0.5s ease; }

    .logoContainer {
        width: 10rem;
        margin: auto;
    }
}

@keyframes fade-in {
    0% {
        transform: scale(1.1);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
