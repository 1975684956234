// Colors - Slate
$slate-lightest: #b6c0ce;
$slate-light: #7f8fa4;
$slate: #687387;
$slate-dark: #596376;

// Colors - Secondary
$white: #ffffff;
$secondary: #273754;
$secondary-dark: #1e232d;
$secondary-accent: #2E4267;
$secondary-shadow: #232F44;
$black: #000000;

// Colors - Primary
$primary-light: #87b8f0;
$primary: #0f71e2;
$primary-dark: #0c5ebb;

// Colors - Alert
$success-light: #02ea73;
$success: #00d166;
$success-dark: #00b056;
$warning-light: #ffd252;
$warning: #ffbd00;
$warning-dark: #f3b400;
$danger-light: #ff386f;
$danger: #e91852;
$danger-dark: #d7144a;

// Colors - Monochrome
$mono-lightest: #f9fafb;
$mono-light: #eaeef1;
$mono: #dfe2e5;
$mono-dark: #d0d4d7;

// Shadows
$shadow1: 0px 2px 5px rgba(18, 25, 39, 0.8);
$shadow2: 0px 3px 10px rgba(18, 25, 39, 0.7);
$shadow3: 0px 4px 18px rgba(18, 25, 39, 0.5);
$shadow4: 0px 18px 18px rgba(18, 25, 39, 0.5);
$shadow5: 0px 31px 40px rgba(18, 25, 39, 0.35);
$shadow6: 0px 2px 5px rgba(18, 25, 39, 0.5);
$shadow7: 0px 3px 10px rgba(18, 25, 39, 0.4);
$shadow8: 0px 4px 18px rgba(18, 25, 39, 0.4);
$shadow9: 0px 18px 18px rgba(18, 25, 39, 0.24);
$shadow10: 0px 31px 40px rgba(18, 25, 39, 0.25);
$shadow11: 0px 2px 5px rgba(18, 25, 39, 0.3);
$shadow12: 0px 3px 10px rgba(18, 25, 39, 0.3);
$shadow13: 0px 4px 18px rgba(18, 25, 39, 0.1);
$shadow14: 0px 4px 18px rgba(18, 25, 39, 0.1);
$shadow15: 0px 31px 40px rgba(18, 25, 39, 0.15);
$shadow16: 0px 8px 28px rgba(18, 25, 39, 0.2);
$shadow17: 0px 29px 22px rgba(18, 25, 39, 0.35);
$shadow18: 0px 54px 43px rgba(18, 25, 39, 0.4);

// Misc variables
$border-radius: 6px;
$logo-height-large: 4rem;
$breadcrumb-height: 1.5rem;
$sidebar-expand-height: 4.9375rem;
$icon-width: 3.5rem;
$standard-card-size: 12rem;
$snackbar-height: 75px;
$main-padding: 1rem;
$sidebar-margin: 3rem;

// Typography sizes
$body1Size: 1rem;
$body2Size: 0.875rem;
$captionSize: 0.75rem;
$iconButtonSize: 1.125rem;

// Borders
$border1: 1px solid $mono-light;
$border2: 1px solid $secondary-dark;
$border3: 1px solid $secondary;

// Responsive Breakpoints
@mixin bp($size) {
    @if $size == xl {
        @media (min-width: 1300px) { @content; }
    }
    @if $size == lg {
        @media (min-width: 992px) { @content; }
    }
    @else if $size == md {
        @media (min-width: 768px) { @content; }
    }
    @else if $size == sm {
        @media (min-width: 480px)  { @content; }
    }
}
